import { memo } from 'react'
import ContentLoader from 'react-content-loader'

function Spinner(props) {
    return (
        <div className="msg-cont">
            <div className="msg-txt" style={{ paddingTop: '24px' }}>
                <ContentLoader
                    speed={2}
                    primarycolor="#f8f8f8"
                    secondarycolor="#ecebeb"
                    {...props}>
                    <rect x="56" y="6" rx="0" ry="0" width="117" height="6.4" />
                    <rect x="56" y="20" rx="0" ry="0" width="85" height="6.4" />
                    <rect x="0" y="60" rx="0" ry="0" width="270" height="6.4" />
                    <rect x="0" y="78" rx="0" ry="0" width="270" height="6.4" />
                    <rect x="0" y="96" rx="0" ry="0" width="201" height="6.4" />
                    <rect x="0" y="0" rx="0" ry="0" width="48" height="48" />
                    <rect x="0" y="120" rx="0" ry="0" width="270" height="1" />
                </ContentLoader>
            </div>
        </div>
    )
}

export default memo(Spinner)

/**
 * 
 * @param { import('react').PropsWithChildren<{}> } props
 */
export function ChatSpinner(props) {
    return (
        <ContentLoader
            speed={2}
            primarycolor="#f8f8f8"
            secondarycolor="#ecebeb"
            height="60px"
            width="120px"
            {...props}>
            <rect x="56" y="6" rx="0" ry="0" width="117" height="6.4" />
            <rect x="56" y="20" rx="0" ry="0" width="85" height="6.4" />
            <rect x="0" y="0" rx="0" ry="0" width="48" height="48" />
        </ContentLoader>
    )
}

export const Loading = () => (<div className="loading"></div>)
